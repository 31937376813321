/* istanbul ignore file -- @preserve */
import { createRouter, createWebHistory } from 'vue-router';

export const ROUTE_NAMES = {
    BE_SETTINGS: 'settings',
    TEST: 'test',
};

export const routes = [
    {
        path: '/test',
        component: () => import('@/pages/TestPage.vue'),
    },
    {
        path: '/settings',
        component: () => import('@/pages/BESettingsPage.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export { router };
